<template>
  <div class="position-relative">
    <input
      type="text"
      class="form-control form-control-sm"
      v-model="filterItem"
    />
    <CIcon
      name="cil-x"
      @click.native="filterItem = null"
      class="cil-x-icon position-absolute"
    ></CIcon>
  </div>
</template>
<script>
import * as _ from 'lodash';
export default {
  name: 'FilterMessage',
  props: {
    field: String,
  },
  data() {
    return {
      filterItem: '',
    };
  },

  watch: {
    filterItem: {
      deep: true,
      handler: function(val) {
        this.$emit('handleChangeData', { value: val, field: this.field });
      },
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.cil-x-icon {
  top: 5px;
  right: 5px;
}
</style>
